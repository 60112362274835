.project-wrapper {
    position: relative;
    max-width: 250px;
    min-width: 250px;
    margin: 10px 10px;
    height: 250px;
    cursor: pointer;
    transform-style: preserve-3d;
    transition: all 300ms ease-in-out;
}

.project-wrapper-front {
    position: absolute;
    max-width: 100%;
    min-width: 100%;
    height:100%;
    box-shadow: 10px 10px 10px black;
    display: flex;
    align-items: center;
    justify-content: center;
    backface-visibility: hidden;
    transform: rotateY(0deg);
}

.project-wrapper-back {
    position: absolute;
    max-width: 100%;
    min-width: 100%;
    height:100%;
    box-shadow: 10px 10px 10px black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    backface-visibility: hidden;
    transform: rotateY(180deg);
}

.logo {
    opacity: 1;
    width: 170px;
    height: 170px;
    filter: drop-shadow(5px 5px 5px #222); 
}

img {
    opacity: .5;
    width: 250px; 
    height: 250px;
    transition: all 300ms ease-in-out;
}

@media only screen and (min-width: 1200px) {
    .project-wrapper {
        max-width: 300px;
        min-width: 300px;
        height: 300px;
    }
    img {
        width: 300px; 
        height: 300px;
    }
    .flip {
        height: 100px;
    }
}

.flip {
    transform: rotateY(180deg);
    height: 350px;
}

.tile-title {
    position: absolute;
    z-index: 11;
    color: white;
    text-shadow: 2px 2px 2px black;
    width: 225px;
    text-align: center;
}
