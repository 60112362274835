.projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 996px) {
    .projects {
        flex-direction: row;
        justify-content: space-center
    }
}