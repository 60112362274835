@import url("https://fonts.googleapis.com/css2?family=Play&family=Roboto&display=swap");



:root {
  --bgColor1: #FFF;
  --bgColor2: #EEE;
  --rotationTime: 450s;
  /* --color-alt: #4A5356;
  --color-main: #2F749C;
  --color-bg: #A79A89;
  --color-text: #26252A; */

  --color-alt: #884426;
  --color-alt2: #1A424E;
  --color-main: #7C9E3D;
  --color-bg: #FDFFF9;
  --color-text: #3C3D3B;
}

.App {
  /* overflow: hidden; */
  background-color: var(--color-bg);
}
/* 
.background-wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.background {
  position: absolute;
  height: 400%;
  width: 400%;
  left: -150%;
  top: -150%;
  background: var(--bgColor1) repeating-conic-gradient(var(--bgColor1) 0 15deg, var(--bgColor2) 0 30deg);
  animation: pos var(--rotationTime) linear 0s infinite normal forwards;
  z-index: 0;
} */

.header-wrapper {
  position: relative;
  padding-top: 10vh;
  background-color: rgb(0,0,0,0);
}

.content-wrapper {
  margin: 1em 10%;
  /* position: relative;
  top: 10vh;
  text-align: center;
  width: 100vw; */
}

@keyframes pos {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(180deg);
  }
}


.resume-link {
  display: flex;
  justify-content: center;
}