.header-content {
    background-image: url('../../images/header-img2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 10px 10px 10px black;
}

.subtitle {
    margin: 0 10%;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    text-align: center;
    letter-spacing: 2px;
    font-size: 16px;
    color: white;
    text-shadow: 2px 2px 2px black;
}

.title {
    font-size: 2em;
    font-family: 'Play', sans-serif;
    color: white;
    text-shadow: 2px 2px 2px black;
}

.letter {
    display: inline-block;
    transition: top 1s ease;
    position: relative;
    top: 0;
}

.letter-ticker {
    height: 1em;
    overflow: hidden;
    position: relative;
}

.ticker-wrapper {
    margin-bottom: 5%;
}

@media only screen and (min-width: 768px) {
    .keyword {
        font-size: 48px;
    }
    .title {
        font-size: 3em;
    }
    .subtitle {
        font-size: 18px;
        margin: 0 20%;
    }
}

.test {
    border: 1px solid red;
}