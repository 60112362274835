@import url(https://fonts.googleapis.com/css2?family=Play&family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bgColor1: #FFF;
  --bgColor2: #EEE;
  --rotationTime: 450s;
  /* --color-alt: #4A5356;
  --color-main: #2F749C;
  --color-bg: #A79A89;
  --color-text: #26252A; */

  --color-alt: #884426;
  --color-alt2: #1A424E;
  --color-main: #7C9E3D;
  --color-bg: #FDFFF9;
  --color-text: #3C3D3B;
}

.App {
  /* overflow: hidden; */
  background-color: #FDFFF9;
  background-color: var(--color-bg);
}
/* 
.background-wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.background {
  position: absolute;
  height: 400%;
  width: 400%;
  left: -150%;
  top: -150%;
  background: var(--bgColor1) repeating-conic-gradient(var(--bgColor1) 0 15deg, var(--bgColor2) 0 30deg);
  animation: pos var(--rotationTime) linear 0s infinite normal forwards;
  z-index: 0;
} */

.header-wrapper {
  position: relative;
  padding-top: 10vh;
  background-color: rgb(0,0,0,0);
}

.content-wrapper {
  margin: 1em 10%;
  /* position: relative;
  top: 10vh;
  text-align: center;
  width: 100vw; */
}

@-webkit-keyframes pos {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(180deg);
  }
}

@keyframes pos {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(180deg);
  }
}


.resume-link {
  display: flex;
  justify-content: center;
}
.header-content {
    background-image: url(/static/media/header-img2.95764106.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 10px 10px 10px black;
}

.subtitle {
    margin: 0 10%;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    text-align: center;
    letter-spacing: 2px;
    font-size: 16px;
    color: white;
    text-shadow: 2px 2px 2px black;
}

.title {
    font-size: 2em;
    font-family: 'Play', sans-serif;
    color: white;
    text-shadow: 2px 2px 2px black;
}

.letter {
    display: inline-block;
    transition: top 1s ease;
    position: relative;
    top: 0;
}

.letter-ticker {
    height: 1em;
    overflow: hidden;
    position: relative;
}

.ticker-wrapper {
    margin-bottom: 5%;
}

@media only screen and (min-width: 768px) {
    .keyword {
        font-size: 48px;
    }
    .title {
        font-size: 3em;
    }
    .subtitle {
        font-size: 18px;
        margin: 0 20%;
    }
}

.test {
    border: 1px solid red;
}
.about-content p {
    text-align: justify;
    font-size: 16px;
    line-height: 1.5;
    color: var(--color-text);
}

.about-content .first {
    min-height: 450px;
}

.about-content p::first-letter {
    font-size: 2.5em;
    float: left;
    line-height: 1;
    margin: 0 .1em 0 0;
    text-shadow: 1px 1px 5px rgb(20,20,20,.7);
  }

.hide {
    display: none;
    /* margin-left: 100%; */
}

.show {
    /* margin-left: 0; */
    /* transition: all 900ms ease-in-out 1s; */
}

.show-more-text {
    display: flex;
    width: 100%;
    font-style: italic;
    justify-content: center;
    font-weight: bold;
}

.slant {
    -webkit-filter: drop-shadow(3px 3px 3px black);
            filter: drop-shadow(3px 3px 3px black);
}

.slant-left{
    position: relative;
    float: left;
    height: 450px;
    width: 200px;
    background-image: url(/static/media/img1.0a763cf3.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
    shape-outside: polygon(0 0, 100% 0, 75% 100%, 0 100%);
    transform: translate(-10vw );
    margin-right: -8vw;
    margin-bottom: 10px;
    z-index: 0;
}

.slant-right{
    position: relative;
    float: right;
    height: 400px;
    width: 200px;
    background-image: url(/static/media/sd1.32e41c17.jpg);
    background-repeat: no-repeat;
    background-position: 87% 50%;
    background-size: cover;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 25% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 25% 100%);
    shape-outside: polygon(0 0, 100% 0, 100% 100%, 25% 100%);
    transform: translate(10vw);
    margin-left: -8vw;
    margin-bottom: 10px;
    z-index: 0;
}

.slant-bottom{
    position: relative;
    float: left;
    height: 400px;
    width: 200px;
    background-image: url(/static/media/philly1.077d0e33.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-clip-path: polygon(0 0, 75% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 75% 0, 100% 100%, 0 100%);
    shape-outside:polygon(0 0, 75% 0, 100% 100%, 0 100%);
    transform: translate(-10vw);
    margin-right: -8vw;
    margin-bottom: 10px;
    z-index: 0;
}

.row {
    display: none;
}

.display-lg {
    opacity: 1;
    width: 170px;
    height: 240px;
    box-shadow: 5px 5px 5px black;
}

.display-lg-2 {
    opacity: 1;
    width: 350px;
    height: 240px;
    box-shadow: 5px 5px 5px black;
}

.display-lg-3 {
    opacity: 1;
    width: 300px;
    height: 300px;
    box-shadow: 5px 5px 5px black;
    margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
    .slant {
        display: none;
    }

    .row {
        display: flex;
        justify-content: center;
    }
    .about-content .first {
        min-height: unset;
    }
}
.section-title {
    color: var(--color-main);
}
.projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 996px) {
    .projects {
        flex-direction: row;
        justify-content: space-center
    }
}
.project-wrapper {
    position: relative;
    max-width: 250px;
    min-width: 250px;
    margin: 10px 10px;
    height: 250px;
    cursor: pointer;
    transform-style: preserve-3d;
    transition: all 300ms ease-in-out;
}

.project-wrapper-front {
    position: absolute;
    max-width: 100%;
    min-width: 100%;
    height:100%;
    box-shadow: 10px 10px 10px black;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transform: rotateY(0deg);
}

.project-wrapper-back {
    position: absolute;
    max-width: 100%;
    min-width: 100%;
    height:100%;
    box-shadow: 10px 10px 10px black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transform: rotateY(180deg);
}

.logo {
    opacity: 1;
    width: 170px;
    height: 170px;
    -webkit-filter: drop-shadow(5px 5px 5px #222);
            filter: drop-shadow(5px 5px 5px #222); 
}

img {
    opacity: .5;
    width: 250px; 
    height: 250px;
    transition: all 300ms ease-in-out;
}

@media only screen and (min-width: 1200px) {
    .project-wrapper {
        max-width: 300px;
        min-width: 300px;
        height: 300px;
    }
    img {
        width: 300px; 
        height: 300px;
    }
    .flip {
        height: 100px;
    }
}

.flip {
    transform: rotateY(180deg);
    height: 350px;
}

.tile-title {
    position: absolute;
    z-index: 11;
    color: white;
    text-shadow: 2px 2px 2px black;
    width: 225px;
    text-align: center;
}

.link-list {
    padding-top: 10px;
    color: var(--color-text);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.link {
    margin: 0 10px;
}

@media only screen and (min-width: 996px) {
    .link {
        margin: 0 30px;
    }
}
